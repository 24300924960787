import React from "react"
import styles from './Footer.module.css'
import { Link } from 'gatsby'

const Footer = () => {
  return (
    <footer className={styles.root}>
      Built with <i className="fad fa-glass-whiskey-rocks" /> in London.
      <div className={styles.links}>
        <Link to='/terms'>Terms</Link>
        <Link to='/privacy'>Privacy</Link>
        <Link to='/support'>Support</Link>
      </div>
      
    </footer>
  )
}

export default Footer