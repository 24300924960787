import React from "react"
import { Link } from "gatsby"
import styles from './Header.module.css'

import logo from '../images/logo.png'

const Header = ({ siteTitle }) => (
  <header className={styles.root}>
    <div className={styles.container}>
      <div className={styles.nav}>
        <Link to="/"><img className={styles.logo} src={logo} /></Link>
        <div className={styles.menu}>
          <Link to="/about">About</Link>
          <Link to="/pricing">Pricing</Link>
          <a href={`${process.env.GATSBY_FRONTEND_APP_URL}/login`}>Login</a>
          <a className="btn btn-primary" href={`${process.env.GATSBY_FRONTEND_APP_URL}/signup`}>Sign up</a>
        </div>
      </div>
    </div>
    
  </header>
)

export default Header
